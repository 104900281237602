
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { NoData, LoadingData } from '@gz/styleguide';
import { userStore } from './store/user/userPinia';

@Component({
	components: {
		NoData,
		LoadingData,
	},
})
export default class App extends Vue {
	userStore = userStore();

	created() {
		this.userStore.InitialiseSubscriptions();
		const theme = localStorage.getItem('dark_theme');
		if (theme) {
			if (theme === 'true') {
				this.$vuetify.theme.dark = true;
			} else {
				this.$vuetify.theme.dark = false;
			}
		} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.$vuetify.theme.dark = true;
			localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
		}
	}
}
