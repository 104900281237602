import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/customers',
		name: 'Home',
		component: () =>
			import(/* webpackChunkName: "customers" */ '../views/Customers/ViewCustomers/Customers.vue'),
	},
	{
		path: '/customers/:customerId?/documents',
		name: 'Documents',
		component: () =>
			import(/* webpackChunkName: "sites" */ '../views/Customers/Documents/CustomerDocuments.vue'),
		props: (route) => ({
			customerId: route.params.customerId,
			siteId: route.params.siteId,
		}),
	},
	{
		path: '/customers/audits',
		name: 'Audits',
		component: () =>
			import(/* webpackChunkName: "audits" */ '../views/Customers/ViewCustomers/Audits/Audits.vue'),
	},
	{
		path: '/customers/quotes',
		name: 'Quotes',
		component: () =>
			import(/* webpackChunkName: "quotes" */ '../views/Customers/QuickQuote/QuickQuote.vue'),
	},
	{
		path: '/customers/:customerId?/audits/',
		name: 'Audits',
		props: (route) => ({
			customerId: route.params.customerId,
		}),
		component: () =>
			import(/* webpackChunkName: "audits" */ '../views/Customers/ViewCustomers/Audits/Audits.vue'),
	},
	{
		path: '/customers/:customerId?/audits/:auditId',
		name: 'AuditDetails',
		props: (route) => ({
			auditId: route.params.customerGroupId,
			customerId: route.params.customerId,
		}),
		component: () =>
			import(
				/* webpackChunkName: "auditdetails" */ '../views/Customers/ViewCustomers/Audits/Details/AuditDetails.vue'
			),
	},
	{
		path: '/customers/webaccess',
		name: 'WebAccess',
		component: () =>
			import(/* webpackChunkName: "webaccess" */ '../views/Customers/WebAccess/WebAccess.vue'),
		// props: true,
		props: (route) => ({
			customerGroupId: route.query.customerGroupId,
			customerId: route.params.customerId,
			siteId: route.query.siteId,
		}),
	},

	{
		path: '/customers/:customerId?',
		name: 'CustomerDetails',
		props: (route) => ({
			customerId: route.params.customerId,
		}),
		component: () =>
			import(
				/* webpackChunkName: "customerdetails" */ '../views/Customers/Details/CustomerDetails.vue'
			),
	},
	{
		path: '/customers/:customerId?/Edit',
		redirect: { name: 'Edit Customer Details' },
		name: 'EditCustomer',
		props: (route) => ({
			customerId: route.params.customerId,
		}),
		component: () =>
			import(
				/* webpackChunkName: "editcustomer" */ '../views/Customers/EditCustomer/EditCustomer.vue'
			),
		children: [
			{
				name: 'Edit Customer Details',
				path: 'CustomerDetails',
				component: () =>
					import(
						/* webpackChunkName: "editcustomer" */ '../views/Customers/EditCustomer/Components/PageCustomerDetails.vue'
					),
			},
			{
				name: 'Edit Contract Details',
				path: 'ContractDetails',
				component: () =>
					import(
						/* webpackChunkName: "editcustomer" */ '../views/Customers/EditCustomer/Components/PageContractDetails.vue'
					),
			},
			{
				name: 'Edit Discounts',
				path: 'Discounts',
				component: () =>
					import(
						/* webpackChunkName: "editcustomer" */ '../views/Customers/EditCustomer/Components/PageDiscounts.vue'
					),
			},
			{
				name: 'Edit Invoice Details',
				path: 'InvoiceDetails',
				component: () =>
					import(
						/* webpackChunkName: "editcustomer" */ '../views/Customers/EditCustomer/Components/PageInvoiceDetails.vue'
					),
			},
			{
				name: 'Edit Price Increase Letters',
				path: 'PriceIncreaseLetters',
				component: () =>
					import(
						/* webpackChunkName: "editcustomer" */ '../views/Customers/EditCustomer/Components/PagePriceIncreaseLetters.vue'
					),
			},
			{
				name: 'Edit Waste Transfer Note Details',
				path: 'WasteTransferNoteDetails',
				component: () =>
					import(
						/* webpackChunkName: "editcustomer" */ '../views/Customers/EditCustomer/Components/PageWasteTransferNoteDetails.vue'
					),
			},
		],
	},
	{
		path: '/customers/:customerId?/webaccess',
		name: 'WebAccessCustomer',
		component: () =>
			import(/* webpackChunkName: "webaccess" */ '../views/Customers/WebAccess/WebAccess.vue'),
		// props: true,
		props: (route) => ({
			customerGroupId: route.query.customerGroupId,
			customerId: route.params.customerId,
			siteId: route.query.siteId,
		}),
	},
	{
		path: '/customers/:customerId?/serviceitems',
		name: 'ServiceItems',
		component: () =>
			import(
				/* webpackChunkName: "serviceitems" */ '../views/Customers/ServiceItems/ServiceItems.vue'
			),
		// props: true,
		props: (route) => ({
			customerId: route.params.customerId,
			serviceType: route.query.serviceType,
		}),
	},
	{
		path: '/customers/:customerId?/suspendedservices',
		name: 'SuspendedServices',
		component: () =>
			import(
				/* webpackChunkName: "suspendedServices" */ '../views/Customers/SuspendedServices/SuspendedServices.vue'
			),
		// props: true,
		props: (route) => ({
			customerId: route.params.customerId,
		}),
	},
	{
		path: '/customers/:customerId?/sites/:siteId?',
		name: 'Sites',
		component: () => import(/* webpackChunkName: "sites" */ '../views/Customers/Sites/Sites.vue'),
		// props: true,
		props: (route) => ({
			customerId: route.params.customerId,
			siteId: route.params.siteId,
		}),
	},
	{
		path: '/customers/:customerId?/sites',
		name: 'Sites',
		component: () => import(/* webpackChunkName: "sites" */ '../views/Customers/Sites/Sites.vue'),
		// props: true,
		props: (route) => ({
			customerId: route.params.customerId,
		}),
	},
	{
		path: '/customers/:customerId?/quotes',
		name: 'Quotes',
		component: () =>
			import(/* webpackChunkName: "quotes" */ '../views/Customers/QuickQuote/QuickQuote.vue'),
		// props: true,
		props: (route) => ({
			customerId: route.params.customerId,
		}),
	},
	{
		path: '/customers/:customerId?/quotes/create/:quoteId?',
		name: 'CreateQuote',
		component: () =>
			import(
				/* webpackChunkName: "CreateQuote" */ '../views/Customers/QuickQuote/CreateQuote/CreateQuote.vue'
			),
		// props: true,
		props: (route) => ({
			customerId: route.params.customerId,
			quoteId: route.params.quoteId,
		}),
	},
	{
		path: '/customersupport', // to stop redirect for microfrontends
	},
	{
		path: '/customersupport/*', // to stop redirect for microfrontends
	},
	{
		path: '/accounts', // to stop redirect for microfrontends
	},
	{
		path: '/supplieraccounts', // to stop redirect for microfrontends
	},
	{
		path: '/supplieraccounts/*', // to stop redirect for microfrontends
	},
	{
		path: '*',
		name: 'NotFound',
		redirect: { name: 'Home' },
	},
	// {
	// 	path: '/customers/serviceitems?',
	// 	name: 'ServiceItems',
	// 	component: () =>
	// 		import(/* webpackChunkName: "ServiceItems" */ '../views/Customers/Details/ServiceItems.vue'),
	// },
	// {
	// 	path: '/customers/serviceitems/:serviceItemId?',
	// 	name: 'ServiceItems',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "ServiceItems" */ '../views/Customers/ServiceItems/ServiceItems.vue'
	// 		),
	// },

	// {
	// 	path: '/customers/about',
	// 	name: 'About',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
	// },
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	document.title = to.name + ' - Customers';
	next();
});

export default router;
